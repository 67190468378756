body {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;

    background: #F2F2F2;
}
h1 .App{
    font-max-size: 1.625rem;
}
h2 .App{
    font-max-size: 1.625rem;
}
h3 .App{
    font-max-size: 1.25rem;
}
.row {
    margin: 0;
}
