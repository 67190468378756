.App {
  text-align: center;
  min-height: 100vh;
}
.App > .container, .footer > .container{
  padding: 0px;
}
.px15{
  padding-right: 15px!important;
  padding-left: 15px!important;
}
/*
    loader
 */
.App-logo {
  height: 20vmin;
  pointer-events: none;
}
.footer_bottom{
  position: fixed;
  left: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 2s linear;
  }
}

.App-loader {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.App-loader p{
  margin-top: 10vmin;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*
    End loader
 */

/*
    Navbar
 */
.navbar.navbar-turnir{
  background: linear-gradient(90deg, #FF5722 0%, #FF9800 100%);
}
.navbar-turnir img{
  height: 50px;
}
.onlineBtn i{
  margin-right: 5px;
}
button.btn{
  border-radius: 20px;
}
.btn.btn-white{
  padding: 10px 20px;

  background: #FFFFFF;
  border-radius: 20px;
  height: 36px;

  color: #FF6D00;
  font-weight: bold;
  line-height: 16px;
}
.btn.btn-white-disabled{
  padding: 10px 20px;

  background: #FFFFFF;
  border-radius: 20px;
  height: 36px;

  color: #FF6D00;
  font-weight: bold;
  line-height: 16px;

  opacity: .65;
}
.btn.btn-orange{
  border-radius: 20px;
  background: #FF9800;
  color: #FFFFFF;
}
.btn.btn-orange:hover
{
  color: #FFFFFF;
  background: #FF5722;
}
.btn.btn-outline-orange {
  color: #FF9800;
  border-color: #FF9800;
}
.btn.btn-outline-orange:hover {
  color: #FF5722;
  border-color: #FF5722;
}
/*
    End Navbar
 */

.timer.btn{
  color: #000000;
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 10px;
  margin-right: 10px;
  height: 60px;
}
.online{
  padding: 3px 10px;
  font-size: 12px;
  /* White */
  background: #FFFFFF;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  margin-left: 5px;

  /* Inside Auto Layout */
  align-self: center;
}
.online i{
  margin-right: 5px;
}
.container > .row{
  background-color: #FFFFFF;
  min-height: 35px;
}
.container-xl{
  max-width: 1440px!important;
}
.container .place-time.row {
  background-color: transparent;
}
.container .place-time.row span{
  align-self: center;
}

.place-time.row .col{
  display: flex;
  justify-content: space-between;
}
.teams-and-score, .team-names, .team-scores{
  background: linear-gradient(90deg, #FF5722 0%, #FF9800 100%);
  color: #FFFFFF;
  font-weight: bold;
}
.team-names > div{
  display: flex;
  align-items: center;
  justify-content: center;
}

/**
  Stat params
*/

.stat-params {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  column-gap: 10px;
  row-gap: 15px;
}

/**
  END Stat params
*/

/**
  Modal
*/
.modal{
  top: 62px!important;
  height: calc(100% - 62px)!important;
}
.modal-backdrop{
  top: 62px!important;
  height: calc(100vh - 62px)!important;
}
.modal-content .modal-header,  .modal-content .modal-body{
  padding-bottom: 6px;
}
#modal-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.modal-event-stat-time{
  width: 30%;
}
.modal-event-stat-time input{
  width: 100%;
  text-align: right;
}
.modal-body-subtitle{
  display: flex;
  justify-content: space-between;
}
/**
  End Modal
*/

/**
  Stat params log
*/
.stat-params-log{
  border-bottom: 1px solid #E0E0E0;
}

/**
  END Stat params log
*/
.fullscreen-container {
    min-height: 100vh;
}
.online-events{
    margin-top: -298px;
}
.team-names{
  height: 17vh;
}
.team-names h3{
  font-size: 4rem;
}
.team-scores{
  height: 30vh;
}
.fouls{
  font-size: 2.5rem;
}
.fouls>div{
  font-size: 5.5rem;
  display: block!important;
  line-height: 0.8;
}
.team-scores .display-2{
  font-size: 15rem;
  font-weight: bold;
}
.teams-and-score{
  padding: 3rem 0;
}

@media (max-width: 991.98px) {
  .stat-params {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    column-gap: 10px;
    row-gap: 15px;
  }
}
@media (max-width: 575.98px) {
  .navbar-turnir img{
  height: 36px;
}
  .fullscreen-container {
    min-height: fit-content;
}
  .online-events{
  margin-top: 0px;
}
  .team-names, .team-scores{
  height: auto;
}
  .team-names h3{
  font-size: 1.75rem;
}
  .fouls{
  font-size: 1rem;
}
  .fouls>div{
  font-size: inherit;
  display: block!important;
  line-height: 0.8;
}
  .stat-params {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    column-gap: 10px;
    row-gap: 15px;
  }

  .display-2 {
    font-size: 4.0rem !important;
  }
  .h2, h2 {
    font-size: 1.5rem!important;
  }
  .h3, h3 {
    font-size: 1.2rem!important;
  }
  .h4, h4 {
    font-size: 1.1rem!important;
  }
  .navbar{
    padding: .5rem .5rem!important;
  }
  .navbar .btn.btn-white{
    font-size: 0.8rem;
    padding: 10px!important;
  }
  .timer.btn{
    margin-right: 5px!important;
    font-size: 16px;
    height: 36px;
  }
  .teams-and-score{
  padding: 0;
}
}


